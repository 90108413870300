<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from "../../../helpers/fakebackend/auth-header";

import { required } from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "User Access Control",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Multiselect,
    Layout,
    PageHeader,
  },

  data() {
    return {
      isTableBusy: false,
      isRoleTableBusy: false,
      tableData: [],
      title: "User Access Control",
      items: [
        {
          text: "Addons",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      isEdit: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "created_at",
      sortDesc: true,
      daterange: [],
      status: "",
      submitted: false,
      employeeTypes: [],
      itemTitle: "",
      typeID: "",
      price: "",
      showDismissibleAlert: false,
      loading: false,
      role: "",
      loginType: "",
      defualtRole: true,
      loginTypeArr: [
        { name: "CloudKitch Admin", id: 1 },
        { name: "Restaurant", id: 2 },
        { name: "Corporate", id: 3 },
        { name: "Principle", id: 4 },
      ],
      roleSelected: false,
      roleName: "",
      selectRestData: "",
      selectRestArr: [],
      selectCorpData: "",
      selectCorpArr: [],
      selectPrinData: "",
      selectPrinArr: [],
      restSelected: false,
      corpSelected: false,
      prinSelected: false,
      restaurant: "",
      getRestID: "",
      corporte: "",
      getCorpID: "",
      principle: "",
      getPrinID: "",
      restArr: [],
      corpArr: [],
      prinArr: [],
      isFliterVisible: false,
      isRestVisible: false,
      isCorpVisible: false,
      isPrinVisible: false,
      loginTypeId: "",
      selectDataID: "",
      roleType: "",
      roleTypeArr: [],
      publicRole: "",
      publicRoleArr: [
        "Yes",
        "No",
      ],
      path: "",
      editRight: "",
      deleteRight: "",
      empTypeData: "",
      compID: "",
      compName: "",
      employeeDataTable: "",
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "role",
          sortable: true,
        },
        {
          key: "roleType",
          sortable: true,
        },
        {
          key: "entity",
          sortable: true,
        },
        {
          key: "publicAccess",
          sortable: true,
        },
        {
          key: "configure",
          sortable: false,
        },
        {
          key: "created",
          label: "Date",
          sortable: true,
        },
      ],
      roleFields: [],
      roleTable: [],
      roleFooter: [],
    };
  },

  validations: {
    loginType: {
      required,
    },
    role: {
      required,
    },
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
  },

  methods: {
    checkUserRights() {
      this.path = this.$route.path;
      this.axios
        .post(this.$loggedRole+"/checkUserRights", {
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          path: this.path,
        })
        .then((result) => {
          this.editRight = result.data.data.isEdit;
          this.deleteRight = result.data.data.isDelete;
          // console.log(result.data.data);
        });
    },

    getSelectedLoginType(value) {
      // console.log(value.name);
      if (value.id != 1) {
        this.roleSelected = true;
        this.defualtRole = true;
        this.roleName = value.name;
        ({
          el: "#defualtRoleDiv",
          data: {
            roleSelected: true,
          },
        });

        this.restArr = [];
        this.restSelected = false;
        ({
          el: "#selectRestDiv",
          data: {
            restSelected: false,
          },
        });

        this.corpArr = [];
        this.corpSelected = false;
        ({
          el: "#selectCorpDiv",
          data: {
            corpSelected: false,
          },
        });

        this.prinArr = [];
        this.prinSelected = false;
        ({
          el: "#selectPrinDiv",
          data: {
            prinSelected: false,
          },
        });
      } else {
        this.roleSelected = false;
        ({
          el: "#defualtRoleDiv",
          data: {
            roleSelected: false,
          },
        });
        this.restArr = [];
        this.restSelected = false;
        ({
          el: "#selectRestDiv",
          data: {
            restSelected: false,
          },
        });

        this.corpArr = [];
        this.corpSelected = false;
        ({
          el: "#selectCorpDiv",
          data: {
            corpSelected: false,
          },
        });

        this.prinArr = [];
        this.prinSelected = false;
        ({
          el: "#selectPrinDiv",
          data: {
            prinSelected: false,
          },
        });
      }
    },

    getAllRestData(value) {
      this.loginTypeId = 2;
      this.getRestID = value.restID;
      // this.loadRoleTypeData();
    },

    getAllCorpData(value) {
      this.loginTypeId = 3;
      this.getCorpID = value.corporateID;
      // this.loadRoleTypeData();
    },

    getAllPrinData(value) {
      this.loginTypeId = 4;
      this.getPrinID = value.principleID;
      // this.loadRoleTypeData();
    },

    // Get All Restuarants Data
    getAllRestuarants() {
      this.axios.post(this.$loggedRole+"/getAllRestaurants").then((result) => {
        this.selectRestArr = result.data.data;
      });
    },

    // Get All Corporates Data
    getAllCorporates() {
      this.axios.post(this.$loggedRole+"/getAllCorporates").then((result) => {
        this.selectCorpArr = result.data.data;
      });
    },

    // Get All Principles Data
    getAllPrinciples() {
      this.axios.post(this.$loggedRole+"/getAllPrinciples").then((result) => {
        this.selectPrinArr = result.data.data;
      });
    },

    getAllCloudkitch() {
      this.axios.post(this.$loggedRole+"/getAllCloudkitch").then((result) => {
        this.selectPrinArr = result.data.data;
      });
    },

    getRoleTypeData(value) {
      this.restaurant = "";
      this.getRestID = "",
      this.selectRestArr = [];
      
      this.corporte = "";
      this.getCorpID = "",
      this.selectCorpArr = [];

      this.principle = "";
      this.getPrinID = "",
      this.selectPrinArr = [];

      this.isRoleTableBusy = true;
      if (value.id == 2) {
        (this.isFliterVisible = true), (this.isRestVisible = true);
        this.isCorpVisible = false;
        this.isPrinVisible = false;
        ({
          el: "#selectFilterData",
          data: {
            isRestVisible: true,
            isCorpVisible: false,
            isPrinisible: false,
          },
        });
        this.getAllRestuarants();
      } else if (value.id == 3) {
        (this.isFliterVisible = true), (this.isRestVisible = false);
        this.isCorpVisible = true;
        this.isPrinVisible = false;
        ({
          el: "#selectFilterData",
          data: {
            isRestVisible: false,
            isCorpVisible: true,
            isPrinisible: false,
          },
        });
        this.getAllCorporates();
      } else if (value.id == 4) {
        (this.isFliterVisible = true), (this.isRestVisible = false);
        this.isCorpVisible = false;
        this.isPrinVisible = true;
        ({
          el: "#selectFilterData",
          data: {
            isRestVisible: false,
            isCorpVisible: false,
            isPrinisible: true,
          },
        });
        this.getAllPrinciples();
      } else {
        (this.isFliterVisible = false), (this.isRestVisible = false);
        this.isCorpVisible = false;
        this.isPrinVisible = false;
        ({
          el: "#selectFilterData",
          data: {
            isRestVisible: false,
            isCorpVisible: false,
            isPrinisible: false,
          },
        });
        this.getAllCloudkitch();
      }
      this.loginTypeId = value.id;
    },

    loadRoleTypeData() {
      if (this.publicRole == null || this.publicRole == "") {
        this.publicRole = 'No';
      }

      if (this.loginTypeId == 2) {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getRoleTypeData", {
            loginTypeID: this.loginTypeId,
            restID: this.getRestID,
            publicRole: this.publicRole,
          })
          .then((result) => {
            this.isTableBusy = false;
            this.tableData = result.data.data;
            this.roleTypeArr = result.data.data;
          });
      } else if (this.loginTypeId == 3) {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getRoleTypeData", {
            loginTypeID: this.loginTypeId,
            corpID: this.getCorpID,
            publicRole: this.publicRole,
          })
          .then((result) => {
            this.isTableBusy = false;
            this.tableData = result.data.data;
            this.roleTypeArr = result.data.data;
          });
      } else if (this.loginTypeId == 4) {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getRoleTypeData", {
            loginTypeID: this.loginTypeId,
            prinID: this.getPrinID,
            publicRole: this.publicRole,
          })
          .then((result) => {
            this.isTableBusy = false;
            this.tableData = result.data.data;
            this.roleTypeArr = result.data.data;
          });
      } else {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getRoleTypeData", {
            loginTypeID: 1,
            publicRole: this.publicRole,
          })
          .then((result) => {
            this.isTableBusy = false;
            this.tableData = result.data.data;
            this.roleTypeArr = result.data.data;
          });
      }

      if (this.loginTypeId == 2) {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getAllUserRole", {
            loginTypeID: this.loginTypeId,
            restID: this.getRestID,
          })
          .then((result) => {
            this.isRoleTableBusy = false;
            this.roleTable = result.data.tableData;
            this.roleFields = result.data.fields;
            this.roleFooter = result.data.footer;
          });
      } else if (this.loginTypeId == 3) {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getAllUserRole", {
            loginTypeID: this.loginTypeId,
            corpID: this.getCorpID,
          })
          .then((result) => {
            this.isRoleTableBusy = false;
            this.roleTable = result.data.tableData;
            this.roleFields = result.data.fields;
            this.roleFooter = result.data.footer;
          });
      } else if (this.loginTypeId == 4) {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getAllUserRole", {
            loginTypeID: this.loginTypeId,
            prinID: this.getPrinID,
          })
          .then((result) => {
            this.isRoleTableBusy = false;
            this.roleTable = result.data.tableData;
            this.roleFields = result.data.fields;
            this.roleFooter = result.data.footer;
          });
      } else {
        this.isRoleTableBusy = true;
        this.axios
          .post(this.$loggedRole+"/getAllUserRole", {
            loginTypeID: 1,
          })
          .then((result) => {
            this.isRoleTableBusy = false;
            this.roleTable = result.data.tableData;
            this.roleFields = result.data.fields;
            this.roleFooter = result.data.footer;
          });
      }
    },

    checkSelectRole() {
      if (this.defualtRole == false) {
        this.restArr = [];
        this.restSelected = false;
        ({
          el: "#selectRestDiv",
          data: {
            restSelected: false,
          },
        });
        this.corpSelected = false;
        ({
          el: "#selectCorpDiv",
          data: {
            corpSelected: false,
          },
        });
        this.prinSelected = false;
        ({
          el: "#selectPrinDiv",
          data: {
            prinSelected: false,
          },
        });
      } else {
        if (this.loginType.id == 2) {
          // Resturant
          this.restArr = [];
          this.restSelected = true;
          ({
            el: "#selectRestDiv",
            data: {
              restSelected: true,
            },
          });
          this.axios
            .post(this.$loggedRole+"/getRestaurantList", {
              restID: this.$storageData.profile.restID,
              restBranchID: this.$storageData.profile.restBranchID,
              empID: this.$storageData.profile.pid,
              empTypeID: this.$storageData.profile.empTypeID,
              city: this.$storageData.profile.city,
            })
            .then((result) => {
              this.restArr = result.data.data;
              console.log(this.restArr);
            });
        } else if (this.loginType.id == 3) {
          // Corporate
          this.corpArr = [];
          this.corpSelected = true;
          ({
            el: "#selectCorpDiv",
            data: {
              corpSelected: true,
            },
          });
          this.axios.get(this.$loggedRole+"/getCorporateList").then((result) => {
            this.corpArr = result.data.data;
          });
        } else if (this.loginType.id == 4) {
          // Principle
          this.prinArr = [];
          this.prinSelected = true;
          ({
            el: "#selectPrinDiv",
            data: {
              prinSelected: true,
            },
          });
          this.axios.post(this.$loggedRole+"/getPrincipleList").then((result) => {
            this.prinArr = result.data.data;
          });
        }
      }
    },

    submitForm() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append("role", this.role);
        formData.append("loginType", this.loginType.id);
        if (this.loginType.id == 1) {
          formData.append("defualtRole", 'true');
        } else {
          formData.append("defualtRole", this.defualtRole);
        }
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        switch (this.loginType.id) {
          case 2:
            if (
              this.selectRestData.restID != "" &&
              this.selectRestData.restID != undefined
            ) {
              formData.append("selectDataID", this.selectRestData.restID);
            } else {
              formData.append("selectDataID", this.selectDataID);
            }
            break;
          case 3:
            if (
              this.selectCorpData.corporateID != "" &&
              this.selectCorpData.corporateID != undefined
            ) {
              formData.append("selectDataID", this.selectCorpData.corporateID);
            } else {
              formData.append("selectDataID", this.selectDataID);
            }
            break;
          case 4:
            if (
              this.selectPrinData.principleID != "" &&
              this.selectPrinData.principleID != undefined
            ) {
              formData.append("selectDataID", this.selectPrinData.principleID);
            } else {
              formData.append("selectDataID", this.selectDataID);
            }
            break;
          default:
            formData.append("selectDataID", this.selectDataID);
            break;
        }

        let apiName = "addRoleAccess";

        this.$root.$emit("bv::hide::modal", "modal-addRoleAccess");

        this.axios
          .post(apiName, formData, {
            headers: authHeader(),
          })
          .then((result) => {
            this.loading = false;
            this.showDismissibleAlert = true;

            this.status = result.data.data;
            //this.$router.go();

            this.role = "";
            this.defualtRole = "";
            this.selectDataID = "";
            
            setTimeout(() => { 
                this.showDismissibleAlert = false; 
            },2000);
            // location.reload();

            this.getEmployeeTypes();
          })
          .catch(function (error) {
            this.loading = false;
            console.error(error.response);
            alert("Something went Wrong");
          });
      }
    },

    updateForm(editFlag) {
      this.loading = true;
      let formData = new FormData();
      let apiName = "updateUserRoleAccess";

      if (editFlag == 1) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }

      if (this.isEdit == true) {
        this.alertMsg = "Details Updated Successfully !";

        formData.append("role", this.role);
        formData.append("empTypeID", this.empTypeID);
        this.$root.$emit("bv::hide::modal", "modal-userAccess");
      }
      this.axios
        .post(apiName, formData, {
          headers: authHeader(),
        })
        .then((result) => {
          this.loading = false;
          this.showDismissibleAlert = true;

          this.status = result.data.data;
          
          this.role = "";

          setTimeout(() => {
            this.showDismissibleAlert = false;
          }, 2000);
          this.getEmployeeTypes();
        })
        .catch(function (error) {
          this.loading = false;
          console.error(error.response);
          alert("Something went Wrong");
        });
    },

    getRestaurantList() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.$storageData.profile.city,
        })
        .then((result) => {
          this.restaurants = result.data.data;
        });
    },

    getEmployeeTypes() {
      this.isTableBusy = true;
      this.axios.post(this.$loggedRole+"/getEmployeeTypes").then((result) => {
        this.isTableBusy = false;
        this.tableData = result.data.data;
        this.employeeTypes = result.data.data;
      });
    },

    openModal(empTypeID, role) {
      this.$root.$emit("bv::show::modal", "modal-userAccess");
      this.role = role;
      this.empTypeID = empTypeID;
    },

    addRoleModal() {
      this.role = "";
      this.defualtRole = "";
      this.selectDataID = "";
      this.loginType = "";
      this.defualtRole = false;
      this.selectRestData = "";
      this.selectCorpData = "";
      this.selectPrinData = "";

      this.roleSelected = false;
      ({
        el: "#defualtRoleDiv",
        data: {
            roleSelected: false,
        },
      });
      this.restArr = [];
      this.restSelected = false;
      ({
        el: "#selectRestDiv",
        data: {
          restSelected: false,
        },
      });

      this.corpArr = [];
      this.corpSelected = false;
      ({
        el: "#selectCorpDiv",
        data: {
          corpSelected: false,
        },
      });

      this.prinArr = [];
      this.prinSelected = false;
      ({
        el: "#selectPrinDiv",
        data: {
          prinSelected: false,
        },
      });

      this.$root.$emit("bv::show::modal", "modal-addRoleAccess");
      this.role = "";
    },

    empDataModal(empTypeID, compID, compName, loginType) {
      this.$root.$emit("bv::show::modal", "modal-empData");
      this.empTypeData = empTypeID;
      this.compID = compID;
      this.compName = compName;
      this.loginType = loginType;

      this.axios
        .post(this.$loggedRole+"/getEmpData", {
          empTypeID: this.empTypeData,
          compID: this.compID,
          loginType: this.loginType,
        })
        .then((result) => {
          this.employeeDataTable = result.data.data;
        });
    },

    applyFilter() {
      this.loadRoleTypeData();
    },

    clearFilter() {
      // Clear All Filters
      this.isFliterVisible = false;
      this.isRestVisible = false;
      this.isCorpVisible = false;
      this.isPrinVisible = false;

      // Clear All Data From Table
      this.roleTable = [];
      this.roleFields = [];
      this.roleFooter = [];

      // Clear Data From Varialbes
      this.roleType = "";
      this.restaurant = "";
      this.corporte = "";
      this.principle = "";
      this.loginTypeId = 1;
      this.publicRole = "";

      this.getEmployeeTypes();
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.checkUserRights();
    this.getEmployeeTypes();

    if (this.$route.params.type == "edit") {
      if (this.$route.params.id) {
        sessionStorage.setItem("addonGroupID", this.$route.params.id);
      }
    } else {
      sessionStorage.setItem("addonGroupID", 0);
    }
    this.addonGroupID = sessionStorage.getItem("addonGroupID");
    if (this.addonGroupID > 0) {
      this.getAddonGroupByID(this.addonGroupID);
      this.isEdit = true;
    }
  },

  middleware: "authentication",
};
</script>

<template lang="en">
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert"  variant="success" dismissible>
        Role Added Successfully!
      </b-alert>
    </div>

    <!-- FILTER START -->
    <div class="card" style="margin-top: -55px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Role Type -->
          <div class="col-md-2">
            <label>Role Type</label>
            <multiselect v-model="roleType" :options="loginTypeArr" track-by="id" label="name" @select="getRoleTypeData" :show-labels="false"></multiselect>
          </div>
          <!-- Role Type End -->
          <div class="col-md-2" id="selectFilterData" v-show="isFliterVisible">
            <!-- Restaurant -->
            <div v-show="isRestVisible">
              <label>Restaurant</label>
              <multiselect v-model="restaurant" :options="selectRestArr" track-by="restID" label="name" @select="getAllRestData" :show-labels="false"></multiselect>
            </div>
            <!-- Restaurant End -->
            <!-- Company -->
            <div v-show="isCorpVisible">
              <label>Corporate</label>
              <multiselect v-model="corporte" :options="selectCorpArr" track-by="corporateID" label="corporateName" @select="getAllCorpData" :show-labels="false"></multiselect>
            </div>
            <!-- Company End -->
            <!-- Principle -->
            <div v-show="isPrinVisible">
              <label>Principle</label>
              <multiselect v-model="principle" :options="selectPrinArr" track-by="principleID" label="principleName" @select="getAllPrinData" :show-labels="false"></multiselect>
            </div>
            <!-- Principle End -->
          </div>
          <!-- Public Role -->
          <div class="col-md-2">
            <label>Public Role</label>
            <multiselect v-model="publicRole" :options="publicRoleArr" :show-labels="false"></multiselect>
          </div>
          <!-- Public Role End -->
          <!-- Filter Button -->
          <div class="col-md-4 mt-1">   
              <button class="btn btn-themeOrange mt-3" v-on:click="applyFilter()"> Apply Filter </button>&nbsp;
              <button  class="btn btn-themeBrown mt-3" v-on:click="clearFilter()"> Clear Filter </button>
          </div>
          <!-- Filter End -->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <!-- v-for="(data, index) in employeeTypes" -->
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Role List</span>
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-4" style="margin-bottom: 15px">
                        <div role="group" class="btn-group">
                          <button  class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light" @click="addRoleModal();"> 
                            Add Role <i class="uil-user-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select style="margin-left: 5px; margin-right: 5px" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                            &nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-4 row">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        striped
                        hover
                        outlined
                        bordered
                        :items="tableData"
                        :fields="fields"
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="isTableBusy"
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(Action)="data">
                          <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px; cursor:pointer; color: #F3766A;" @click="openModal(data.item.empTypeID, data.item.role)"></i>
                        </template>
                        <template v-slot:cell(role)="data">
                          <span>{{ data.item.role }}</span>
                        </template>
                        <template v-slot:cell(loginType)="data">
                          <span>{{ data.item.loginType }}</span>
                        </template>
                        <template v-slot:cell(entity)="data">
                          <span>{{ data.item.entity }}</span>
                        </template>
                        <template v-slot:cell(configure)="data">
                          <router-link :to="{ name: 'configureAccessControl', params: { empTypeID: data.item.empTypeID, type: 'view', roleName: data.item.role }, }">
                            <button class="btn btn-themeBrown" style="padding: 1px 4px; font-size: 12px">
                              Configure Permissions
                            </button>
                          </router-link>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Table -->
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
                <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                        <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Roles Statistics</span>
                </template>
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-4">
                        <div class="table-responsive mb-0">
                          <b-table-simple stacked responsive striped hover outlined bordered :busy="isRoleTableBusy">
                            <template #table-busy>
                              <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                              </div>
                            </template>
                            <b-thead>
                                <b-th v-for="(roleField, index) in roleFields" label-sort-desc>
                                  <div>
                                    <span class="tooltiptext" :title="roleField.key">{{roleField.key}}</span>
                                  </div>
                                </b-th>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(rTable, key) in roleTable">
                                <b-td v-for="(rTableD, index) in roleTable[key]">
                                  <div v-if="isNaN(rTableD.value)" :title="rTableD.value">
                                    <span>{{rTableD.value}}</span>
                                  </div>
                                  <div v-else-if="rTableD.value > 0" class="align-right">
                                    <a href="#" @click="empDataModal(rTableD.empTypeID, rTableD.compID, rTableD.compName, loginTypeId);">{{rTableD.value}}</a>
                                  </div>
                                  <div v-else class="align-right">
                                    <span>{{rTableD.value}}</span>
                                  </div>
                                </b-td>
                              </b-tr>
                            </b-tbody>
                            <!-- <b-tfoot>
                              <b-tr v-for="(rTable, key) in roleTable">
                                <b-td v-for="(rTableD, index) in roleTable[key]">
                                  <div v-if="isNaN(rTableD.total)">
                                    <span>{{rTableD.total}}</span>
                                  </div>
                                  <div v-else class="align-right">
                                    <span>{{rTableD.total}}</span>
                                  </div>
                                </b-td>
                              </b-tr>
                            </b-tfoot> -->
                          </b-table-simple>
                          <!-- <b-table class="roleTable"
                            striped
                            
                            outlined
                            bordered
                            :items="roleTable"
                            :fields="roleFields"
                            responsive
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            :busy="isRoleTableBusy"
                          >
                            <template #table-busy>
                              <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                              </div>
                            </template>
                            <template v-for="field in roleFields" v-slot:[`head(${field})`]="data">
                              <div class="tooltip"><span class="tooltiptext" >{{data.field.role}}</span></div>
                            </template>
                          </b-table> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </b-tab>                      
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xl-12 col-sm-12" v-for="(data, index) in employeeTypes" :key="index">
        <div class="card">
          <div class="card-body">
            <h6 class="align-center mb-1 mt-1" style="color:#F3766A">{{data.role}}</h6>
            <p class="align-center text-muted">{{data.loginType}}</p>
            <div class="row">
              <div class="col-xl-9 col-md-9" style="width:auto;">
                <button class="btn btn-themeBrown" style="padding: 1px 4px; font-size:12px;" v-on:click="applyFilter()">
                  Configure Permissions
                </button>
              </div>
              <div class="col-xl-3 col-md-3">
                <i class="uil uil-edit-alt" title="Edit" style="font-size: 16px; color:#A4827D;" @click="openModal(data.empTypeID,data.role)"></i>  
              </div>
            </div>                                         
          </div>
        </div>
      </div>
    </div> -->
    
    <b-modal id="modal-addRoleAccess" title="Add Role Access" title-class="font-18">
      <div>
        <div class="col-md-12">
          <label class="form-label" for="formrow-role-input">Enter Role Name: </label>
          <input type="text" class="form-control" id="formrow-role-input" v-model="role" :class="{'is-invalid': submitted && $v.role.$error,}">
          <div v-if="submitted && $v.role.$error" class="invalid-feedback">
            <span v-if="!$v.role.required">This value is required.</span>
          </div>
        </div>
        <div class="col-md-12">
          <label> Select Role Type: </label>
          <multiselect 
            v-model="loginType" 
            :options="loginTypeArr" 
            @select="getSelectedLoginType" 
            :show-labels="false" 
            track-by="id" 
            label="name" 
            :class="{'is-invalid': submitted && $v.loginType.$error,}"
            >
          </multiselect>
          <div v-if="submitted && $v.loginType.$error" class="invalid-feedback">
            <span v-if="!$v.loginType.required">This value is required.</span>
          </div>
        </div> 
        <div class="col-md-12" id="defualtRoleDiv">
          <div v-show="roleSelected">
            <input class="form-check-input" type="checkbox" id="defualtRoleCheck" v-model="defualtRole" v-on:click="checkSelectRole()">
            <label class="form-check-label" for="defualtRoleCheck" style="margin-left: 5px;">
              Add default/public role.
            </label>
            <p>This role will be available in all {{ roleName }}.</p>
          </div>
        </div>
        <div class="col-md-12" id="selectRestDiv">
          <div v-show="restSelected">
            <label> Select Restaurant: </label>
            <multiselect 
              v-model="selectRestData" 
              :options="restArr"
              :show-labels="false" 
              track-by="restID" 
              label="name" 
              :class="{'is-invalid': submitted && $v.selectRestData.$error,}"
              >              
            </multiselect>
          </div>
        </div>
        <div class="col-md-12" id="selectCorpDiv">
          <div v-show="corpSelected">
            <label> Select Corporate: </label>
            <multiselect 
              v-model="selectCorpData" 
              :options="corpArr"
              :show-labels="false" 
              track-by="corporateID" 
              label="corporateName" 
              :class="{'is-invalid': submitted && $v.selectCorpData.$error,}"
              >              
            </multiselect>
          </div>
        </div>
        <div class="col-md-12" id="selectPrinDiv">
          <div v-show="prinSelected">
            <label> Select Principle: </label>
            <multiselect 
              v-model="selectPrinData" 
              :options="prinArr"
              :show-labels="false" 
              track-by="principleID" 
              label="principleName" 
              :class="{'is-invalid': submitted && $v.selectPrinData.$error,}"
              >              
            </multiselect>
          </div>
        </div>   
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button :disabled="loading" variant="primary" size="sm" class="col-2 float-right" @click="submitForm()">
            Submit
          </b-button>
          <b-spinner v-if="loading" class="m-2 col-3" variant="primary" role="status"></b-spinner>
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-userAccess" title="Update Access" title-class="font-18">
      <div>
        <div class="col-md-6">
          <label class="form-label" for="formrow-role-input">Role </label>
          <input type="text" class="form-control" id="formrow-role-input" v-model="role" :class="{'is-invalid': submitted && $v.role.$error,}">
          <div v-if="submitted && $v.role.$error" class="invalid-feedback">
            <span v-if="!$v.role.required">This value is required.</span>
          </div>

          <input type="hidden" v-model="empTypeID">
        </div>    
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right" @click="updateForm(1)">
            Submit
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-empData" size="lg" title="Employee Details" title-class="font-18" hide-footer>
      <div class="row">
        <div class="col-md-8 mb-2">
          <div v-if="loginTypeId == 1">Cloudkitch Name:
            <span style="font-weight: 500"> {{ compName }}</span>
          </div>
          <div v-if="loginTypeId == 2">Restaurant Name:
            <span style="font-weight: 500"> {{ compName }}</span>
          </div>
          <div v-else-if="loginTypeId == 3">Corporate Name:
            <span style="font-weight: 500"> {{ compName }}</span>
          </div>
          <div v-else-if="loginTypeId == 4">Principle Name:
            <span style="font-weight: 500"> {{ compName }}</span>
          </div>
        </div>
      </div>
      <div class="table-responsive mb-0">
        <table class="table table-striped table-hover">
          <thead>
            <tr> 
            <td>User Name</td>
            <td>Email Id</td>
            <td>Phone No</td>
            <td>Role</td>
            </tr>
          </thead>
          <tbody v-for="(data, index) in employeeDataTable" :key="data.id">
            <tr>
                <td>{{data.empName}}</td>
                <td>{{data.empEmail}}</td>
                <td>{{data.empNumber}}</td>
                <td>{{data.role}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
<style>
.table th {
  min-width: 80px;
  max-width: 120px;
}
</style>

